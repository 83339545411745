const HandleServerRes = (res, url, method) => {
	const endpoint = `${method.toUpperCase()} ${url.split('/').slice(3).join('/')}`;
	if (res.err) {
		console.error(` 📡 Server Response - ${endpoint} 📡\n`, res.data.err);
	} else if (res.data) {
		if (process.env.NODE_ENV !== 'production') {
			console.log(process.env.NODE_ENV);
			console.log(` 📡 Server Response - ${endpoint} 📡\n`, res.data);
		}
	} else if (!res.data) {
		console.warn(
			` 📡 Server Response - ${endpoint} 📡\n`,
			' Something really weird happened.\n',
			' The server sent an unexpected response',
		);
		console.warn(res);
	}
};

export default HandleServerRes;
